import React, { useEffect, useState } from "react";
import background from "../images/image3_resized.jpg";
import PlayAudio from "../PlayAudio";
import { useNavigate } from 'react-router-dom';

function Success() {
  const navigate = useNavigate();
  let params = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [data, setSetData] = useState(null);

  
  const handleDownload = async (e) => {
    
    e.preventDefault(); // Prevent default link behavior
    if(loading)  return;
    setLoading(true); // Set loading state while the request is being processed

    try {    
      // Assuming the image URL is in the 'imageUrl' field in the response data
      if (data.receiptURL) {
        // Trigger the image download
        const link = document.createElement('a');
        link.href = data.bufferImage; // URL returned from the POST request
        link.download = data.receiptName || `${params.get('session_id')}recipt.png`; // Name of the downloaded image
        // Append the link to the document body (it needs to be in the DOM to work)
        document.body.appendChild(link);
        link.click();
        // Remove the link from the DOM after the download
        document.body.removeChild(link);
        navigate(`/?#liveamount`);
        
      } else {
        console.error('No image URL found in the response');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching image:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const getReceipt = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      const response = await fetch(`https://api.yaavaay.com/requestRecieptasPng/${params.get('session_id')}`, requestOptions)
      // Assuming the server responds with the image path or URL
      const dataRes = await response.json();
      if(dataRes?.receiptURL){
        setSetData(dataRes);
      }else{
        setTimeout(() =>  !(data?.receiptURL)&& window.location.reload(), 5000);
      }
    } catch (error) {
      console.log("error:", error)
      setTimeout(() =>  window.location.reload(), 5000);
    }
  }

  useEffect(() => {
    getReceipt();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${background})`,
        minHeight: "100vh"
      }}
    >
      <PlayAudio/>
      <p style={{ color: "white", marginTop: 50, fontWeight:"bold",fontFamily:'"Tahoma", sans-serif' }}>
        Your payment has been processed.
      </p>
      <p style={{ color: "white", marginBottom: "20px", fontWeight:"bold",fontFamily:'"Tahoma", sans-serif' }}>
        Copy of receipt sent to your email.
      </p>

      {data?.receiptURL ? (
        <div style={{ marginTop: '30px', marginBottom: '10px', minHeight:"834px" }} className="imageConReceipt">
          <img 
            src={data.receiptURL}
            alt="Receipt Image"
            className="imageReceipt"
            style={{ display: 'block', objectFit: 'cover',width: "834px"}}
          />
       </div>
      ) : (
        <div className="success-loading-div">
          <div style={{marginTop:-60}}>
              <p className="success-Loadign-first">Please wait a moment</p>
              <p className="success-Loadign-first">Your receipt is loading . . .</p>
          </div>
        </div>
      )}

{/* <div style={{ marginTop: '30px', marginBottom: '10px' }}>
        <iframe style={{ border: 'none' }} src={"https://api.yaavaay.com/requestpdfashtml/" + params.get('session_id')} type="application/pdf" width="834" height="950"></iframe>
      </div> */}

      <div style={{ fontSize: "20px", textAlign: "center", marginBottom: "40px", display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <a
        style={{
          letterSpacing: '0px',
          color: '#03a5fc',
          display: 'inline-block',
          width: '150px',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
        href="#"
        onClick={handleDownload}
      >
        Download
      </a>
        {/* <a style={{ letterSpacing: "0px", color: "#03a5fc", display: "inline-block", width: "150px" }} href={"https://api.yaavaay.com/requestpdf/" + params.get('session_id') + ".pdf"} download="your-yaavaay-receipt.pdf">Download</a> */}
      </div>
    </div>
  );
}

export default Success;
