import React from 'react';
import './Footer.css'; // Optional: Add styles if needed

const Footer = () => {
  return (
    <section className="text-section-footer">
    <footer className="footer-last">
      <div className="footer-container">
        <p><span>www.YAAVAAY.com</span> is limited service expires at midnight 31st December 2025 to
          transfer all registered applicant(s) ONLY towards individual tailor-made products
          and services for each applicant(s) for pending LIVE extraterrestrials contact through
          Y-GEN’s 2026 development. Collectively filtering final management via SEFIHEAN to develop the Physical Platform, Suppressed Technologies, Welcome Uniform, Health &
          Safety Paradigm, Intrinsic Securities, Broadcast Channel, The Calling Performance and Contact Protocols.</p>
          <p className='copyWrite'> Copyright 2022.</p>
      </div>
    </footer>
    </section>
  );
};

export default Footer;
